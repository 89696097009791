import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BaseStorageService } from './base-storage.service';

@Injectable({
    providedIn: 'root'
  })
export class LocalStorageService extends BaseStorageService {

    public KEY_SYSTEM_LANGUAGE = "SYSTEM_LANGUAGE";
    public KEY_LANGUAGE_LIST = "SYSTEM_LANGUAGE_LIST" ;
    public KEY_LANGUAGE_ID = "SYSTEM_LANGUAGE_ID";
    public KEY_PERMISSIONS_CREATE = "PERMISSIONS_CREATE";
    // token can be replaced with SESSION_STORAGE if needed
    constructor(@Inject(LOCAL_STORAGE) protected storage: StorageService) {
        super(storage);
     }
}