
<div *ngIf="loading" class="container-fluid">
  <app-loader type="list" ></app-loader>
</div>

<div *ngIf="!loading">
  <app-breadcrumb [isHeader]="true"></app-breadcrumb>
  <div class="bg-secondary px-3">
    <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
      <app-filter-buttons [title]="'pages.role.filter.title'" (clickClearFilter)="clearFilter()" (clickCloseFilter)="closeFilter()">
      </app-filter-buttons>
    </div>
    <div [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
      <div class="justify-content-center">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <app-select *ngIf="!classifiersLoading"
                [label]="'pages.role.list.data.register'"
                [parentPropertyName]="'RegisterId'"
                [selectedId]="searchData.RegisterId"
                [list]="classifierData.REGISTERS"
                (selectChange)="onSelectChange($event)"> 
            </app-select>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
                <label class="" for="Name">{{'pages.role.list.data.name' | translate}}:</label>
                <input id="Name" class="form-control" type="text" (keyup.enter)="onFilter()" [(ngModel)]="searchData.Name" />
            </div>
          </div>
      </div>
      </div>
    </div>
    <div class="form-group mt-3" [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
      <a translate class="btn btn-primary cp-font-weight-700"
        (click)="onFilter()">pages.common.list.searchButton
      </a>
    </div>
   </div>

  <input #fileInput type="file" (change)="onFileSelected($event)" accept=".json" style="display: none;">

<ng-container>
  <div class="table-holder">
    <div class="grid-classifier-header">
      <div>    
        <h3 class="me-auto" translate>pages.role.list.title</h3>
      </div>
      <div *ngIf="isFilterClosed">
          <a class="btn btn-outline-primary open-filter-margin btn-right float-end" (click)="openFilter()" translate>pages.common.list.filterButton
              <i class="fa-solid fa-list"></i>
          </a>
      </div>
  </div>
  <app-base-filter 
      [disableAddNew]="disableAddNew"
      [btnAddNewTitle]="'pages.role.list.addNewButton'"
      (clickCreate)="onCreate()"
      [disableImportExport]=false
      [btnImportTitle]="'pages.role.list.import'"
      (clickImport)="onImport()"
      [btnExportTitle]="'pages.role.list.export'"
      (clickExport)="onExport()"
      (clickBack)="onBack()">
    </app-base-filter>
    <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" 
      [tableColumns]="tableColumns" 
      [listActions]="actions"
      [classifiers]="classifierData"
      (edit)="onEdit($event)" 
      (view)="onView($event)"
      (delete)="onDelete($event)"  
      (toList)="onToList($event)" 
      (sortChange)="onSortChange($event)">
    </app-data-table>
  </div>
  <app-pagging [page]="searchData.Page"
    [pageSize]="searchData.PageSize"
    [total]="totalItem"
    (pageSizeChange)="onPageSizeChange($event)"
    (pageChange)="onPageChange($event)" ></app-pagging>
</ng-container>
</div>

