import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { SubSystem } from '../../../../enumerators/classifier.enum';
import { formEnum } from 'projects/urp/src/app/enumerators/Forms.enum';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent {  

  formEnum = formEnum.internalUserForm;
  listUrl = routesEnum.userList.url;
  actions: string[] = ['view', 'edit', 'delete'];
  apiUrl: string;

  module: SubSystem = SubSystem.UM;
  listingMongoId = this.environment.formIds.userListFormId;

  constructor( protected router: Router,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
      this.apiUrl = this.environment.api.umUser;
    }

  protected onCreate(): void {
    void this.router.navigate([`${this.listUrl}/${routesEnum.form.url}`], { queryParams: { FormCode: this.formEnum } });
  }
}
