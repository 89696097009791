import { StorageService } from 'ngx-webstorage-service';

export class BaseStorageService {
    // token can be replaced with SESSION_STORAGE if needed
    constructor(protected storage: StorageService) { }

    public store(key: any, value: any): void {
        this.storage.set(key, value);
    }

    public retrieve(key: string): any {
        return this.storage.get(key);
    }

    public exists(key: string): boolean {
        return this.storage.has(key);
    }

    public clear() : void {
        return this.storage.clear();
    }

}