/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, EventEmitter, Inject, ViewChild } from '@angular/core';
import { routesEnum } from '../../../enumerators/routesEnum';
import { DefaultParams } from '../../../models/filter.model';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { formEnum } from '../../../enumerators/Forms.enum';
import { TableColumn } from '../../../models/table-column.model';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { HttpService } from '../../../services/http-service/http.service';
import { Location } from '@angular/common';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { FormsService } from 'projects/urp/src/app/services/forms.service/forms.service';
import { ObjectHelpers } from 'projects/core/src/lib/shared/helpers/object-helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormioComponent } from '@formio/angular';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../../services/storage.service/local-storage.service';
@Component({
  selector: 'app-registration-object-files-list',
  templateUrl: './registration-object-files-list.component.html'
})
export class RegistrationObjectFileListComponent extends BaseListComponent {

    searchData = new DefaultParams('FileName desc');
    defaultSearchData = new DefaultParams('AttachmentUploadDateTime desc');
    form: any;
    formOptions: any;
    cachedLanguages = {};
    @ViewChild('formio') formioComponent: FormioComponent;
    query: any = {};
    languageSubscription: Subscription;
 
    apiUrl = this.environment.api.attachments;
    listUrl = routesEnum.registerFileList.url;
    formEnum = formEnum.rorApplicationForm;
    actions: string[] = ['download'];
    RegisterId: any;
    RegistrationNumberExact: any;

    tableColumns = [
        new TableColumn('RegisterServiceName', 'pages.registerFilesList.data.orderedEServiceName'),
        new TableColumn('TrackingNumber', 'pages.registerFilesList.data.trackingNo'),
        new TableColumn('FileName', 'pages.registerFilesList.data.fileName'),
        new TableColumn('AttachmentTypeNaturalKey', 'pages.registerFilesList.data.attachmentType', 'cls', "CLS.ATTACHMENT-TYPE"),
        new TableColumn('AttachmentUploadDateTime', 'pages.registerFilesList.data.attachmentUploadDate', 'datetime'),
        new TableColumn(null, null, 'action')
    ];
    refreshForm = new EventEmitter();
    language = new EventEmitter<string>();
    
    constructor(protected toastrService: ToastrServiceExt,
        protected modal: BsModalService,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected readonly keycloak: KeycloakService,
        protected location: Location,
        protected httpService: HttpService,
        protected formsService: FormsService,
        private helper: HelperService,
        private httpClient: HttpClient,
        protected translateService: TranslateService,
        protected storage: LocalStorageService,
        @Inject(APP_CONFIG) private readonly environment: IConfig) {
        super(toastrService, modal, router, activatedRoute, keycloak, location, httpService);
        this.form = { display: 'form', components: []};
        this.getOptions();
    }

    protected override loadClassifiers(): void {
        this.RegisterId = this.searchData['RegisterId'];
        this.RegistrationNumberExact = this.searchData['RegistrationNumberExact'];
        this.classifiersLoading = true;
        this.httpService.get(`${this.environment.api.classifier}/selectSearch?ClassifierDomainNaturalIds=ATTACHMENT-TYPE`).subscribe(res => {
            this.classifierData.attachmentTypes = res;
            this.classifiersLoading = false;
            this.loadList();
            this.loadFilterForm();
        }, (err) => {
            this.toastrService.serverError(err);
        });
    }

    protected override async onView(item: any): Promise<void> {
        void this.router.navigate([ `${this.listUrl}/form`], { queryParams: this.createQueryParams ?? { formCode: item.FormCode, RegisterId: this.searchData['RegisterId'], id: item.Id, readOnly: true } });
    }

    protected override async onDownload(item: any): Promise<void> {
        this.httpClient.get(item.DownloadUrl, { responseType: 'blob' }).subscribe(res => {
            const file = new Blob([res], { type: 'application/octet-stream' });
            saveAs(file, item.FileName);
        });
    }

    protected override clearFilter(): void {
        this.searchData = Object.assign({}, this.defaultSearchData, {  relativeTo: this.activatedRoute, RegisterId: this.RegisterId, RegistrationNumberExact: this.RegistrationNumberExact});
        this.refreshForm.emit({
            submission: {
              data: {
                filter: ObjectHelpers.unflatten(this.searchData)
              }
            }
          });
        this.loadList();
    }
    loadFilterForm() {
        this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
            this.addLanguage(this.getCurrentLanguage());
          });
      
        this.formsService.getFormById(this.environment.formIds.registerAttachmentsListFilterFormId).subscribe(res => {        
            let response : any = res;
            this.form.components = response.components;
            this.searchData = Object.assign({}, this.defaultSearchData);
            this.refreshForm.emit({
                form: this.form
              });
        });
    }
    getOptions(): void {
    const lang = this.getCurrentLanguage();
    this.formOptions = {
        template: 'bootstrap5',
        disableAlerts: true,
        noAlerts: true,
        language: lang.toLowerCase(),
        i18n: {
        [lang.toLowerCase()]: this.storage.retrieve(`translations_${lang}`)
        }
    }
    this.cachedLanguages[lang.toLowerCase()] = true;
    }
    getCurrentLanguage(): string {
    return this.translateService.currentLang;
    }
    addLanguage(lang: string) : void {
        if (!this.cachedLanguages[lang.toLowerCase()]) {
          this.formioComponent.formio.addLanguage(lang.toLowerCase(), this.storage.retrieve(`translations_${lang}`));
          this.cachedLanguages[lang.toLowerCase()] = true;
        }
        this.language.emit(lang.toLowerCase());
    }
    public formioCustomEvent(event: any): void {
        const mergedObject = {
          ...this.searchData,
          ...event.data
        };
        switch (event.type) {
          case 'filter':
            this.searchData = mergedObject;
            this.searchData['RegisterId'] =  this.RegisterId;
            this.searchData['RegistrationNumberExact'] = this.RegistrationNumberExact;
            this.onFilter();
            break;
          case 'clear':
            this.clearFilter();
            break;
          default:
            alert(`Event ${event.type} is not handled for this form yet.`);
        }
    }
    public formioChange(event: any): void {
        if(JSON.stringify(event.metadata) == JSON.stringify({})){
          for (const key of this.query.keys ?? []) {
            const values = this.query.getAll(key);
              if (values.length === 1) {
                this.searchData[key] = values.shift();
              }
              else {
                this.searchData[key] = values;
              }    
          }
          this.searchData['RegisterId'] =  this.RegisterId;
          this.searchData['RegistrationNumberExact'] = this.RegistrationNumberExact;
          this.refreshForm.emit({
            submission: {
              data: {
                filter: ObjectHelpers.unflatten(this.searchData)
              }
            }
          });
        }
        if(event.data?.filter){
          const mergedObject = {
            ...this.searchData,
            ...ObjectHelpers.flatten(event.data.filter)
          };
          this.searchData = mergedObject;
        }
      }
  
    render() : void {
      const lang = this.getCurrentLanguage();
      this.language.emit(lang.toLowerCase());
    }    
}
