import { Component, Inject, Injector } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { BsModalService } from "ngx-bootstrap/modal";
import { FormsService } from "projects/urp/src/app/services/forms.service/forms.service";
import { HelperService } from "projects/urp/src/app/services/helper.service/helper.service";
import { HttpService } from "projects/urp/src/app/services/http-service/http.service";
import { LocalStorageService } from "projects/urp/src/app/services/storage.service/local-storage.service";
import { ToastrServiceExt } from "projects/urp/src/app/services/toastr.service/toastr.service";
import { BaseFormComponent } from "../../shared/base-form/base-form.component";
import { IConfig } from "projects/urp/src/app/models/config.interface";
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { TranslateService } from "@ngx-translate/core";
import { MenuService } from "projects/urp/src/app/services/menu.service/menu.service";

@Component({
    selector: 'printout-qr-code-verification',
    templateUrl: '../../shared/base-form/base-form.component.html',
    styleUrls: ['../../shared/base-form/base-form.component.scss']
})
export class PrintoutQRCodeVerificationComponent extends BaseFormComponent {

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected formsService: FormsService,
        protected location: Location,
        protected toastrService: ToastrServiceExt,
        protected httpService: HttpService,
        protected helper: HelperService,
        protected readonly keycloak: KeycloakService,
        protected modal: BsModalService,
        protected storage: LocalStorageService,
        protected injector: Injector,
        protected translateService: TranslateService,
        protected menuService: MenuService,
        @Inject(APP_CONFIG) protected readonly environment: IConfig) {
        super(route, router, formsService, location, toastrService, httpService, helper, keycloak, modal, storage, translateService, injector, environment);
    }

    protected override getFormByIdAndLoad(formId: any, id: any) {
        this.formsService.getPublicFormById(formId).subscribe(res => {
            void this.setFormDataAndLoad(res, id);
        });
    }

    protected override async loadData(formCode, id: string) {
        try {
            const res = await this.httpService.get(`${this.environment.api.printoutQrCodeVerification}/verify/${this.params.roId}/${id}/${this.params.s}`).toPromise();
            const data = res.Data ? JSON.parse(res.Data) : res;
            this.setSubmission(data, id);
        } catch (e) {
            this.error(e);
        }
    }

}