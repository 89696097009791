import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { MenuService } from 'projects/urp/src/app/services/menu.service/menu.service';
import { Guid } from 'guid-typescript';
import { ContextService } from 'projects/urp/src/app/services/context.service/context.service';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { SessionStorageService } from 'projects/urp/src/app/services/storage.service/session-storage.service';


@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html'
})
export class HeaderUserComponent implements OnInit {
  public userProfile: any;
  public userInfo: any;
  public institution = '';
  private readonly url = `${routesEnum.profile.url}`;
  public token: string;
  userMenuShown = false;
  representative: any;
  representatives: any;
  subscription$: Subscription;

  constructor(public keycloak: KeycloakService,
    private router: Router,
    private httpService: HttpService,
    private toastr: ToastrServiceExt,
    private eRef: ElementRef,
    private menuService: MenuService,
    private context: ContextService,
    @Inject(APP_CONFIG) private readonly environment: IConfig,
    private readonly sessionStorage: SessionStorageService) {
  }

  public ngOnInit(): void {
    this.menuService.refreshMenuEvent.subscribe(() => {
      this.refreshUser();
    })

  }

  refreshUser(): void {
    void this.keycloak.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        // TODO find out why user profile doesnt update after token refesh
        void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {
          this.userInfo = user;
          if (!user['userId']) {
            this.menuService.showMenuEvent.next(false);
            void this.router.navigate([this.url]);
          }
          else {
            combineLatest([this.context.representations, this.context.representationId])
              .subscribe(result => {
                if (result[0]) {
                  this.representatives = result[0];
                  this.representative = this.representatives.Data.filter(x => x.Id == result[1])[0];
                }
              });
          }
        });
        void this.keycloak.loadUserProfile().then(profile => {
          this.userProfile = profile;
        })
      }
    });
  }

  ChangeContext(id: Guid): void {
    this.context.changeContext(id);
    this.httpService.get(`${this.environment.api.umRepresentatives}/ContextSwitch/${id}`).subscribe(() => {
      // TODO
    });
  }

  public Logout(): void {
    void this.keycloak.logout().then(() => {
      this.keycloak.clearToken();
      this.sessionStorage.clear();
      void this.router.navigate([routesEnum.home.url]);
    });
  }

  public async Profile(): Promise<void> {
    await this.router.navigate([this.url]);
  }

  showUserMenu(): void {
    this.userMenuShown = !this.userMenuShown;
    this.checkChevron();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.userMenuShown = false;
      this.checkChevron();
    }
  }
  checkChevron() {
    if (this.userMenuShown) {
      document.getElementById('chevron').classList.add("urp-icon-user-chevron-up");
      document.getElementById('chevron').classList.remove("urp-icon-user-chevron-down");
    } else {
      document.getElementById('chevron').classList.add("urp-icon-user-chevron-down");
      document.getElementById('chevron').classList.remove("urp-icon-user-chevron-up");
    }
  }
}
