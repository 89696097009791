<!-- Desktop -->
<div class="table-responsive d-none d-lg-block">
  <table class="cp-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of tableColumns">
          <ng-container [ngSwitch]="column.type || ''">
            <ng-container *ngSwitchCase="'action'">
              <th scope="col"></th>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <th scope="col" sortable={{column.id}} id="{{column.id}}" (sort)="onSort($event)">
                <div style="display: flex;">
                  <div class="one-line-text-truncate" title="{{column.label | translate}}">
                    {{column.label | translate}} 
                  </div>
                  <span></span>
                </div>
              </th>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let item of tableData">
          <ng-container *ngFor="let column of tableColumns">
            <ng-container [ngSwitch]="column.type || ''">
              <ng-container *ngSwitchCase="'flag'">
                <td class="icons">
                  <span class="flag-icon flag-icon-{{getLanguageCode(column, item)}}"></span>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <td class="icons text-end" >
                  <ng-container *ngIf="ShowAction('claim', item)">
                    <a class="btn--cust btn--light btn--thin btn--min144" (click)="onClaim(item)" title="{{'pages.common.list.claim' | translate }}"
                      alt="Claim">{{'pages.common.list.claim' | translate }}</a>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('edit', item) && HasRight('save', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-edit " (click)="onEdit(item)" title="{{'pages.common.list.edit' | translate}}" alt="edit"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('view', item) && HasRight('read', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-eye-open" (click)="onView(item)" title="{{'pages.common.list.view' | translate}}" alt="view"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('delete', item) && HasRight('delete', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-delete" (click)="onDelete(item)" title="{{'pages.common.list.delete' | translate}}" alt="delete"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('download', item) && HasRight('read', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-download" (click)="onDownload(item)" title="{{'pages.common.list.download' | translate}}" alt="download"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('list', item) && HasRight('read', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-external-link" (click)="goToList(item)" translate title="pages.common.list.view" alt="pages.common.list.view"></span>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'cls'">
                <td class="align-middle" [ngClass]="column.class || 'status'">
                  <span [ngClass]="setClass(column, item)">{{getTranslation(column, item) | dynamicTranslate | async}}</span>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'guid'">
                <ng-container *ngIf="item.IsDeleted; else elseBlock">
                  <td class="align-middle" [ngClass]="column.class || 'status'">
                    <del>
                      <span [ngClass]="setClass(column, item)">{{getGuidTranslation(column, item) | dynamicTranslate | async}}</span>
                    </del>
                  </td>
                </ng-container>
                <ng-template #elseBlock>
                  <td class="align-middle" [ngClass]="column.class || 'status'">
                    <span [ngClass]="setClass(column, item)">{{getGuidTranslation(column, item) | dynamicTranslate | async}}</span>
                  </td>
                </ng-template>
              </ng-container>
              <!-- TODO sutvarkyti datos formata, kad nebutu ihardcodintas -->
              <ng-container *ngSwitchCase="'date'">
                <td>{{ item[column.id] | date: 'yyyy/MM/dd'}}</td>
              </ng-container>
              <ng-container *ngSwitchCase="'datetime'">
                <td>{{ item[column.id] | date: 'yyyy/MM/dd HH:mm'}}</td>
              </ng-container>
              <ng-container *ngSwitchCase="'notTranslatable'">
                <ng-container *ngIf="item.IsDeleted; else elseBlock">
                  <td><del>{{ item[column.id] }}</del></td>
                </ng-container>
                <ng-template #elseBlock>
                  <td>
                    <div class="multi-line-text-truncate">
                      {{ item[column.id] }}
                    </div>
                  </td>
                </ng-template>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="item.IsDeleted; else elseBlock">
                  <td><del>{{ item[column.id] | dynamicTranslate | async }}</del></td>
                  </ng-container>
                  <ng-template #elseBlock>
                    <td>
                      <div class="multi-line-text-truncate" title="{{item[column.id] | dynamicTranslate | async}}">
                        {{ item[column.id] | dynamicTranslate | async }}
                      </div>
                    </td>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </tr>
       </ng-container>
       <ng-container *ngIf="tableData.length < 1">
          <tr>
            <td [attr.colspan]="tableColSpan" class="text-center">{{'pages.common.list.noDataFound' | translate }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
</div>
<!-- Mobile -->
<div class="container">
<div class="d-flex flex-column gap-16 d-lg-none cp-mt-20">
  <ng-container *ngFor="let item of tableData;">
    <div class="cp-card cp-pt-10 cp-pb-10 cp-pe-16 cp-ps-16">
      <ng-container *ngFor="let column of tableColumns; let lastItem = last;">
        <div class="d-flex justify-content-between cp-text-xsmall cp-text-black">
          <span *ngIf="column.type !== 'action'" class="cp-font-weight-600 cp-w-50" translate>{{column.label}}</span>
          <span *ngIf="column.type === 'action'" class="cp-font-weight-600 cp-w-50" translate>pages.common.list.action</span>  
            <ng-container [ngSwitch]="column.type || ''">
              <ng-container *ngSwitchCase="'cls'">
                <span class="cp-w-50" [ngClass]="setClass(column, item)">{{getTranslation(column, item) | dynamicTranslate | async}}</span>
              </ng-container>                
              <ng-container *ngSwitchCase="'guid'">
                <span class="cp-w-50">{{getGuidTranslation(column, item) | dynamicTranslate | async}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <span class="cp-w-50">{{ item[column.id] | date: 'yyyy/MM/dd'}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'datetime'">
                <span class="cp-w-50">{{ item[column.id] | date: 'yyyy/MM/dd HH:mm'}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <span class="cp-w-50" >
                  <ng-container *ngIf="ShowAction('claim', item)">
                    <a class="btn--cust btn--light btn--thin btn--min144" (click)="onClaim(item)" title="Claim"
                      alt="Claim">{{'pages.common.list.claim' | translate }}</a>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('edit', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-edit " (click)="onEdit(item)" title="{{'pages.common.list.edit' | translate}}" alt="edit"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('view', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-eye-open" (click)="onView(item)" title="{{'pages.common.list.view' | translate}}" alt="view"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('delete', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-delete" (click)="onDelete(item)" title="{{'pages.common.list.delete' | translate}}" alt="delete"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('download', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-download" (click)="onDownload(item)" title="{{'pages.common.list.download' | translate}}" alt="download"></span>
                  </ng-container>
                  <ng-container *ngIf="ShowAction('list', item)">
                      <span role="button" class="ms-1 c-icon-grey urp-icon urp-icon-external-link" (click)="goToList(item)" translate title="pages.common.list.view" alt="pages.common.list.view"></span>
                  </ng-container>
                </span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="item.IsDeleted; else elseBlock">
                <td><del>{{ item[column.id]}}</del></td>
                </ng-container>
                <ng-template #elseBlock>
                  <span class="cp-w-50">{{ item[column.id] }}</span>
                </ng-template>
              </ng-container>
          </ng-container>
        </div>
        <hr *ngIf="!lastItem" class="cp-mt-10 cp-mb-10" />
      </ng-container>
    </div>
  </ng-container>
</div>
</div>

