import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IConfig } from '../../../models/config.interface';
import { DefaultParams } from '../../../models/filter.model';
import { TableColumn } from '../../../models/table-column.model';
import { HelperService } from '../../../services/helper.service/helper.service';
import { HttpService } from '../../../services/http-service/http.service';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { BaseListComponent } from '../../shared/base-list/base-list.component';
import { Location } from '@angular/common';
import { formEnum } from '../../../enumerators/Forms.enum';
import { routesEnum } from '../../../enumerators/routesEnum';

@Component({
  selector: 'app-my-office-representatives-list',
  templateUrl: './my-office-representatives-list.component.html',
  styleUrls: ['./my-office-representatives-list.component.scss']
})
export class MyOfficeRepresentativesListComponent extends BaseListComponent {

  searchData = new DefaultParams('CreationDate asc');
  defaultSearchData = new DefaultParams('CreationDate asc');
  actions: string[] = ['view', 'edit'];

  tableColumns = [
    new TableColumn('Username', 'pages.representative.list.data.username'),
    new TableColumn('UserFullName', 'pages.representative.list.data.userFullName'),
    new TableColumn('ValidFrom', 'pages.representative.list.data.validFrom', 'datetime'),
    new TableColumn('ValidTo', 'pages.representative.list.data.validTo', 'datetime'),
    new TableColumn(null, null, 'action')
  ];

  listUrl = routesEnum.myOfficeRepresentativesList.url;
  formEnum = formEnum.representativeForm;

  constructor(protected toastrService: ToastrServiceExt,
    protected modal: BsModalService,
    protected router: Router,
    protected readonly keycloak: KeycloakService,
    protected httpService: HttpService,
    protected location: Location,
    protected activatedroute: ActivatedRoute,
    protected helperService: HelperService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    super(toastrService, modal, router, activatedroute, keycloak, location, httpService);
    this.apiUrl = `${this.environment.api.umRepresentatives}/AccountRepresentatives`;

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadList();
  }

  protected onCreate(): void {
    void this.router.navigate([`${routesEnum.form.url}`], { relativeTo: this.activatedRoute, queryParams: this.createQueryParams ?? { FormCode: this.formEnum } });
  }

  protected onEdit(item: any): void {
    void this.router.navigate([`${routesEnum.form.url}`], {relativeTo: this.activatedRoute, queryParams: { FormCode: item.FormCode ?? this.formEnum, Id: item.Id } });
  }

  protected onView(item: any): void {
    void this.router.navigate([`${routesEnum.form.url}`], {relativeTo: this.activatedRoute, queryParams: { FormCode: item.FormCode ?? this.formEnum, Id: item.Id, readOnly: true } });
  }
}
