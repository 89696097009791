import { InjectionToken } from "@angular/core";
import { IConfig } from "../models/config.interface";

export const APP_CONFIG = new InjectionToken<IConfig>('environment.configuration');

export const DOMAIN_ENDPOINT = new InjectionToken<string>('domains.endpoint');
export const CLASSIFIER_ENDPOINT = new InjectionToken<string>('classifiers.endpoint');
export const INSTITUTION_ENDPOINT = new InjectionToken<string>('institutions.endpoint');
export const REGISTER_ENDPOINT = new InjectionToken<string>('register.endpoint');
export const REGISTER_FILES= new InjectionToken<string>('filelist.endpoint');
export const LANGUAGE_ENDPOINT = new InjectionToken<string>('languages.endpoint');
export const TRANSLATION_ENDPOINT = new InjectionToken<string>('translations.endpoint');
export const ID_GENERATION_CONFIG_ENDPOINT = new InjectionToken<string>('idGenerationConfig.endpoint');
export const EXTERNAL_CLIENT_ENDPOINT = new InjectionToken<string>('externalClient.endpoint');
export const REGISTRATION_OBJECT_ENDPOINT = new InjectionToken<string>('registration-object.endpoint');
export const APPLICATION_ENDPOINT = new InjectionToken<string>('application.endpoint');
export const SERVICE_ENDPOINT = new InjectionToken<string>('services.endpoint');
export const SERVICE_ENDPOINT_LIST_MERGING_RULES = new InjectionToken<string>('servicesListMergingRules.endpoint');
export const SERVICE_ENDPOINT_FILE_LIST_CONFIGS = new InjectionToken<string>('servicesFileListConfigs.endpoint');
export const SERVICE_ENDPOINT_THUMBNAILS = new InjectionToken<string>('servicesFileListConfigs.endpoint');
export const CRON_JOB_CONFIGURATION_ENDPOINT = new InjectionToken<string>('cronJobConfigurations.endpoint');
export const REGISTER_FUNCTIONALITY_ENDPOINT = new InjectionToken<string>('registerFunctionality.endpoint');
export const ACCOUNT_TYPE_ENDPOINT = new InjectionToken<string>('accountType.endpoint');
export const SMS_PROVIDER_CONFIG_ENDPOINT = new InjectionToken<string>('smsProviderConfig.endpoint');
export const REPRESENTATIVE_ENDPOINT = new InjectionToken<string>('representatives.endpoint');
export const ACCOUNT_ENDPOINT= new InjectionToken<string>('accounts.endpoint');
export const NAME_SIMILARITY_ENDPOINT = new InjectionToken<string>('nameSimilarity.endpoint');