import { Component, Inject, Injector, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IConfig } from '../../../models/config.interface';
import { FormsService } from '../../../services/forms.service/forms.service';
import { HelperService } from '../../../services/helper.service/helper.service';
import { HttpService } from '../../../services/http-service/http.service';
import { LocalStorageService } from '../../../services/storage.service/local-storage.service';
import { MenuService } from '../../../services/menu.service/menu.service';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { APP_CONFIG } from '../../../utility/tokens';
import { UserFormComponent } from '../user/user-form/user-form.component';
import { formEnum } from '../../../enumerators/Forms.enum';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-my-profile',
  templateUrl: '../../shared/base-form/base-form.component.html',
  styleUrls: ['../../shared/base-form/base-form.component.scss']
})
export class MyProfileComponent extends UserFormComponent implements OnInit {

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected formsService: FormsService,
    protected location: Location,
    protected toastrService: ToastrServiceExt,
    protected httpService: HttpService,
    protected helper: HelperService,
    protected readonly keycloak: KeycloakService,
    protected modal: BsModalService,
    protected storage: LocalStorageService,
    protected injector: Injector,
    protected translateService: TranslateService,
    protected menuService: MenuService,
    @Inject(APP_CONFIG) protected readonly environment: IConfig) {
    super(route, router, formsService, location, toastrService, httpService, helper, keycloak, modal, storage, injector, translateService, menuService, environment);
  }

  formCode = formEnum.externalUserForm;

  ngOnInit(): void {
    this.subscription.add(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        if (this.formioComponent)
          this.addLanguage(this.getCurrentLanguage());
      })
    );

    this.activatedRoute.queryParams.subscribe(params => {
      this.submitted = false;
      const formId = params.FormId;
      const formCode = params.FormCode;
      this.params = params;
      this.readOnly = params.readOnly;
      void this.keycloak.loadUserProfile().then(profile => {
        if (profile['attributes']?.userId) {
          this.id = profile['attributes']?.userId[0];
        }

        if (formId) {
          this.getFormByIdAndLoad(formId, this.id);
        }
        else if (formCode) {
          this.formsService.getLastFormByCode(formCode).subscribe(res => {
            res = res[0];
            void this.setFormDataAndLoad(res, this.id);
          });
        }
        else if (this.id) {
          void this.loadDataBeforeForm(this.id);
        }
        else if (this.formCode) {
          this.formsService.getLastFormByCode(this.formCode).subscribe(res => {
            res = res[0];
            void this.setFormDataAndLoad(res, this.id);
          });
        }
      });
    });

    if (this.activatedRoute.snapshot.data['apiController']) {
      this.apiController = this.injector.get<string>(this.activatedRoute.snapshot.data['apiController']);
    }
  }

  protected override async setFormDataAndLoad(res: any, id: any): Promise<void> {
    this.form.components = res.components;
    this.form.display = res.display;
    this.formCode = res.code;
    this.formTitle = res.title;
    this.formVersionNo = res.versionId;
    this.formId = res._id;
    if (this.id) {
      await this.loadData(res.code, id);
    } else {
      void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {
        let data: any = {};
        data.Username = user['preferred_username'];
        data.Email = user['email'];
        this.afterLoad(data);
        this.setSubmission(data, id);
        this.submitted = true;
      });
    }
    this.refreshForm.emit({
      form: this.form
    });
  }

  protected override save(formData: any): void {
    formData.data.FormId = this.formId;
    formData.data.FormCode = this.formCode;
    const data = Object.assign({}, formData.data, this.params);
    delete data.RolesReadonly; // TODO jei galima padaryt kad formio nesiustu sitos reiksmes ji tik perziurai

    if (this.formCode === formEnum.internalUserForm) {
      this.httpService.post(`${this.environment.api.umUser}/SaveInternal`, data).subscribe(res => {
        this.successSave(res, this.formCode);
      }, (e) => {
        this.error(e);
      });
    }
    else if (this.formCode === formEnum.externalUserForm) {
      this.httpService.post(`${this.environment.api.umUser}`, data).subscribe(res => {
        this.successSave(res, this.formCode);
      }, (e) => {
        this.error(e);
      });
    }
    this.clickSuccess.emit(formData.instance);
  }

  protected override setSubmission(res: any, id?: string) {
    if (id) {
      res.Id = id;
      this.id = Guid.parse(id);
    }
    this.submission = { data: res };
    this.submitted = true;
  }
}
