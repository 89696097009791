import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { timer, Subject, Observable } from 'rxjs';
import { SessionStorageService } from '../storage.service/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserActivityService {
    userInactive = false;
    userSessionExpired = false;
    automaticLogoutTime = 90;
    automaticLogoutCountdown = 0;
    tokenRefreshFailed = false;
    private userInactiveEvent = new Subject<boolean>();

    constructor(private keycloak: KeycloakService,
        private sessionStorage: SessionStorageService
    ) {
        this.keycloak.isLoggedIn().then(isLoggedIn => {
            if (isLoggedIn) {
                const myTimer = timer(1000, 1000);
                myTimer.subscribe(() => {
                    if (Date.now() > (this.keycloak.getKeycloakInstance().refreshTokenParsed.exp - this.automaticLogoutTime) * 1000) {
                        if (!this.userInactive) {
                            this.userInactive = true;
                            this.userInactiveEvent.next(true);
                        }
                    }

                    if (this.userInactive) {
                        this.automaticLogoutCountdown--;
                    }
                });
            }
        });
    }

    async userContinueSession(): Promise<void> {
        this.userInactive = false;
        this.automaticLogoutCountdown = this.automaticLogoutTime;
        await this.keycloak.updateToken().then((response) => {
            if (!response) {
                this.tokenRefreshFailed = true;
            }
        })
    }

    userEndSession(): void {
        void this.keycloak.logout(location.origin).then((success) => { 
            this.keycloak.clearToken();
            this.sessionStorage.clear();
          });
    }

    getUserInactivity(): Observable<boolean> {
        return this.userInactiveEvent.asObservable();
    }

    failedToRefreshToken(): void {
        this.tokenRefreshFailed = true;
    }
}
